<template>
  <div class="bg h-screen" :class="themeClassName" :style="`background-image:url('${backgroundImage}')`">
    <div class="overlay"></div>
    <div class="box p-10 bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-xl max-h-screen overflow-y-auto" v-if="errors.length > 0">
      <div class="mb-10">
        <img class="h-8" :src="logoUrl" alt="" />         
      </div>
      <div>
        <h1 class="font-semibold">
          {{ $tk("Common.General.AnUnexpectedErrorOccured") }}
        </h1>
        <ul class="mt-6 list-outside list-disc ml-6 text-sm">
          <li v-for="(error, index) in errors" :key="index">
            {{ error.message }}
          </li>
        </ul>
        <PButton             
            class="mt-8 justify-center"                         
            color="primary"
            @click="onClick"
          >
            {{ $tk("Common.General.GoToLogin") }}
          </PButton>
      </div>      
    </div> 
  </div>  
</template>

<script>

import { mapGetters } from "vuex"
import TokenService from "@/services/TokenService"
import LocaleService from "@/services/LocaleService"
import { getTheme } from "../config"

const theme = getTheme()

export default {

  computed: {
    ...mapGetters(["errors"]),
    logoUrl () {
      return theme.logoBlack
    },
    backgroundImage () {
      return theme.backgroundImage
    },
    themeClassName () {
      return theme.themeClassName
    },
  },

  methods: {
    navigateToLogin () {
      TokenService.removeToken()
      location.href = "/"
    },
    onClick () {
      this.navigateToLogin()
    }
  },
    
  mounted () {        
    this.$i18n.locale = LocaleService.getLocale()
    if (this.errors.length === 0) {
      this.navigateToLogin()
    }
  },
}
</script>

<style >

.bg {
  background-size: cover;
  background-position: top right;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;  
  z-index: 10;  
}

@screen md {
  .box {
    left: 15%;
    right: auto;
    top: 50%;
    transform: translate(-15%, -50%);
  }
}

</style>